import { ZodTypeAny } from 'zod';

export const nullableOptional = <T extends ZodTypeAny>(schema: T) =>
  schema
    .nullable()
    .optional()
    .transform((value) => (value ? value : undefined));
export const stringToBoolean = (value: string) => value === 'true';
export const booleanToString = (value: boolean) => (value ? 'true' : 'false');

export const optionalCheckbox = (value?: boolean) => value ?? false;
